
.intro{
  
   padding: 100px 0 100px 0;
    height: 60vh;
    width: 100%;
    background-image: linear-gradient(hsl(212, 100%, 18%),#1A5F7A);
          position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    color: white;
    
   
}
.intro-details{
    width: fit-content;
    margin: 20px ;
    padding-left: 3rem;
    
}
.intro-details h1{
    font-style: italic;
   opacity: 1;
   font-size: 20px;
    animation-name: textanimation;
    animation-duration: 2s;
}
@keyframes textanimation {
  0%   {font-size: 10px; opacity : 0.1}

  100% {font-size: 20px; opacity: 1;}
}

.tracking-out-contract{
    margin-top:0;
    font-size: 50px;
    font-weight: 600;
    color: white;
    opacity: 1;
    animation-name: panimation;
    animation-duration: 2s;
}
@keyframes panimation {
  0%   {font-size: 30px; opacity : 0.1}

  100% {font-size: 40px; opacity: 1;}
}
#details{
    font-style: italic;
    font-size: 15px;
    opacity: 1;
    animation-name: p1animation;
    animation-duration: 2s;
}
@keyframes p1animation {
  0%   {font-size: 5px; opacity : 0.1}

  100% {font-size: 15px; opacity: 1;}
}
#resume{
 background-color: white;
 color: black;
 font-size: large;
 font-weight: bolder;
 border-radius: 30px;
 padding-left: 5px;
 padding-right: 5px;
}
#resume:hover{
  background-color: #0b242e;
  color: white;
  border:1px solid white;
}
.intro-img{
    height: 50vh;
    width: 50vh;
    border-radius: 50%;
    overflow: hidden;
     
}

#resumebtmlink{
  margin: 0 1.5rem;
  height: max-content;
  width: fit-content;
  padding: 0.5rem;
  font-size: 30px;
  border-radius: 1rem;
  color: black;
 
}
#resumebtmlink:hover{
  color: white;
  background-color: #0b242e;
  border: 1px solid white;
  
  }

#intro-imgone{
  border-radius: 50%;
  object-fit: cover;
    animation-name: imganimation;
    animation-duration: 2s;
}
@keyframes imganimation {
  0%   {height: 10rem; width: 00rem; opacity : 0}

  100% {height: 20rem; width: 20rem; opacity : 1}
}

@media (max-width:786px){
.intro{
  
    padding-top: 10rem;
    height:50vh;
    flex-direction:column-reverse;
}
.intro-details {
    text-align: center;
    margin-bottom: 20px;
  }

  .intro-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
  }
  #intro-imgone{
    height: 100px;
    width: 100px;
    margin-top: 10px;
  }
#resumebutton{
  padding: 5px;
  height: 30px;
  font-size: 16px;
  color: black;
 
}
  .tracking-out-contract {
    font-size: 1.5rem;
  }

  #details {
    font-size: 0.9rem;
    margin-top: 10px;
  }

  #intro-imgone{
    margin-top: ;
height: 10rem;
width: 10rem;

}

.intro-details h1{
font-size: 20px;
}
}


